import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as style from "../styles/stylesLandingPage";
import Header from "../ui/components/LandingHeader/LandingHeader";
import ClientsBar from "../ui/components/ClientsBar/ClientsBar";
import useButtonHandle from "../hooks/useButtonHandle";
import PopupTrigger from "../ui/components/PopupTrigger/popupTrigger";
import HeaderMainAreaComponent from "../ui/components/MainAreaHeader/MainAreaHeader";
import UserProfileTable from "../ui/components/UserProfile/UserProfile";
import MainRender from "../ui/components/MainRender/MainRender";
import useHeader from "../hooks/useHeader";
import usePopup from "../hooks/usePopup";
import { useSortData } from "../hooks/useSortData";

enum Filters {
	Name = "NombreCliente",
	Folder = "CarpetasLength",
	DateMinus = "LatestDate",
	DatePlus = "LatestDate",
}

export const LandingPage: React.FC = () => {
	const location = useLocation();
	const userInfo = location.state?.userInfo;

	// Custom hooks
	const {
		userName,
		folderQuest,
		selectorStatus,
		isFolder,
		isUserName,
		setIsUserName,
		setSelectorStatus,
		setFolderQuest,
		setuserName,
		setisFolder,
	} = useSortData();

	const { isHeaderActivated, handleHeader } = useHeader();

	const { openPopup, setopenPopUp, handdleForm, sethanddleForm, AddNewReturn } =
		usePopup();

	const {
		activeButton,
		handleCompany: handleCompanyFromHook,
		currentData: ElementsData,
		handleNews,
	} = useButtonHandle();

	// Local state
	const [showUserProfile, setShowUserProfile] = useState(false);
	const [searchClientTable, setSearchClientTable] = useState<
		string | undefined
	>();
	const [searchMainTable, setSearchMainTable] = useState<string | undefined>();
	const [fromDate, setFromDate] = useState("None");
	const [toDate, setToDate] = useState("None");

	// Handle search functionality
	const handleSearchDispatch = (search: string) => {
		if (userName && !isFolder) {
			setSearchClientTable(search);
			setSearchMainTable(undefined);
		} else if (!isFolder) {
			setSearchClientTable(undefined);
			setSearchMainTable(search);
		} else {
			setSearchClientTable(undefined);
			setSearchMainTable(undefined);
		}
	};

	// Handle sort selector changes
	const handleSortSelector = async (newStatus: string) => {
		setSelectorStatus(newStatus);
		if (Object.values(Filters).includes(newStatus as Filters)) {
			setIsUserName(false);
			setisFolder(false);
			handleCompanyFromHook();
			setuserName("");
			setFolderQuest("");
		}
	};

	// Handle client selection
	const handleClientSelection = (company_id: string, folder: string) => {
		setuserName(company_id);
		setFolderQuest(folder);
	};

	// Handle company button click
	const handleCompany = useCallback(() => {
		setIsUserName(false);
		setisFolder(false);
		handleCompanyFromHook();
		if (activeButton === "Empresas") {
			setuserName("");
			setFolderQuest("");
		}
	}, [
		activeButton,
		handleCompanyFromHook,
		setFolderQuest,
		setIsUserName,
		setisFolder,
		setuserName,
	]);

	// Update state based on userName and folderQuest changes
	useEffect(() => {
		if (userName.length > 0) {
			setIsUserName(true);
			if (folderQuest === "") {
				setIsUserName(true);
				setisFolder(false);
			} else if (folderQuest.length > 0) {
				setIsUserName(false);
				setisFolder(true);
			}
		}
	}, [folderQuest, setIsUserName, setisFolder, userName]);

	return (
		<style.LandingWrap>
			<style.GridLanding>
				<PopupTrigger
					openPopup={openPopup}
					setopenPopUp={setopenPopUp}
					handdleForm={handdleForm}
					sethanddleForm={sethanddleForm}
					Username={userInfo.username}
				/>

				<style.HeaderGrid>
					<Header
						OnClick={handleHeader}
						PopAdd={AddNewReturn}
						onSearch={handleSearchDispatch}
					/>
				</style.HeaderGrid>

				{!isHeaderActivated && (
					<style.ClientsBarGrid>
						<ClientsBar
							CompanySelections={handleClientSelection}
							UserSelection={setShowUserProfile}
						/>
					</style.ClientsBarGrid>
				)}

				<style.MainAreaGrid
					gridArea={!isHeaderActivated ? "2 / 2 / 5 / 5" : "2 / 1 / 5 / 5"}
				>
					{showUserProfile ? (
						<UserProfileTable userInfo={userInfo} />
					) : (
						<>
							<HeaderMainAreaComponent
								Role={userInfo.role[0]}
								activeButton={activeButton}
								handleCompany={handleCompany}
								handleNews={handleNews}
								selectorStatus={selectorStatus}
								handleSortSelector={handleSortSelector}
								onDateFilter={(fromDate, toDate) => {
									setFromDate(fromDate);
									setToDate(toDate);
								}}
								isFolder={isFolder}
								singleTable={isUserName}
							/>

							<style.ClientsTableDiv>
								<MainRender
									Filter={selectorStatus}
									CompanySelectedId={isUserName ? userName : ""}
									activeButton={activeButton}
									ElementsData={ElementsData}
									isFolder={isFolder}
									isUserName={isUserName}
									SelectedCompany={userName}
									SelectedFolder={folderQuest}
									Role={userInfo.role[0]}
									UserRegister={userInfo.username}
									UserSelected={handleClientSelection}
									DateRange={[fromDate, toDate]}
									searchMain={searchMainTable}
									searchClient={searchClientTable}
								/>
							</style.ClientsTableDiv>
						</>
					)}
				</style.MainAreaGrid>
			</style.GridLanding>
		</style.LandingWrap>
	);
};

export default LandingPage;
