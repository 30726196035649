import { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import "./App.css";
import Router from "./routes/Router";
import colors from "../src/ui/colors";

const AppDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100vw;
	background-color: ${colors.primaryGreen};
`;

const ResolutionWarning = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${colors.primaryGreen};
	color: ${colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 2rem;
	z-index: 9999;

	h2 {
		margin-bottom: 1rem;
	}

	p {
		max-width: 600px;
		margin-bottom: 1rem;
	}
`;

function App() {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const minWidth = 1200;
	const minHeight = 1075;

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Throttle the resize event to prevent excessive re-renders
		let resizeTimeout: number;
		const throttledResize = () => {
			if (!resizeTimeout) {
				resizeTimeout = window.setTimeout(() => {
					resizeTimeout = 0;
					handleResize();
				}, 200); // Only update every 200ms to reduce backend load
			}
		};

		window.addEventListener("resize", throttledResize);
		return () => {
			window.removeEventListener("resize", throttledResize);
			if (resizeTimeout) {
				window.clearTimeout(resizeTimeout);
			}
		};
	}, []);

	// Use useMemo to prevent unnecessary recalculations
	const isResolutionTooSmall = useMemo(
		() => windowSize.width < minWidth || windowSize.height < minHeight,
		[windowSize.width, windowSize.height]
	);

	// Memoize the resolution warning text to prevent re-renders
	const resolutionWarningContent = useMemo(
		() => (
			<ResolutionWarning>
				<h2>Resolución insuficiente</h2>
				<p>
					Para utilizar esta aplicación, necesita una pantalla con resolución
					mínima de 900x900 píxeles.
				</p>
				<p>
					Su resolución actual es: {windowSize.width}x{windowSize.height}{" "}
					píxeles.
				</p>
			</ResolutionWarning>
		),
		[windowSize.width, windowSize.height]
	);

	return (
		<>
			{isResolutionTooSmall ? (
				resolutionWarningContent
			) : (
				<AppDiv>
					<Router />
				</AppDiv>
			)}
		</>
	);
}

export default App;
