import * as styles from "../styles/stylesLogin";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useLoginMutation } from "../apis/auth.api";
import { setAuthState } from "../slices/auth.slice";
import { Toaster, toast } from "sonner";
import { useAppDispatch } from "../app/hooks";
import LoginForm from "../template/Forms/LoginForm/LoginForm";
import { setLocalStorage } from "../utilities/localstorage";

export const LoginPage: React.FC = () => {
	const [isUsernameError, setIsUsernameError] = useState<boolean>(false);
	const [isPasswordError, setIsPasswordError] = useState<boolean>(false);
	const [authError, setAuthError] = useState<string>("");

	const dispatch = useAppDispatch();

	const [login, { isLoading: loginLoading }] = useLoginMutation();
	const navigate = useNavigate();

	const handleLogin = async (username: string, password: string) => {
		setIsUsernameError(!username);
		setIsPasswordError(!password);
		if (!username || !password) return;

		try {
			const response = await login({ username, password });

			if ("error" in response) {
				toast.error(`Incorrect username or password`);
				setAuthError("red");
				return;
			}
			dispatch(setAuthState({ token: response.data.token }));
			setLocalStorage("token", response.data.token);
			if (response.data.user.role[0] === "Creator") {
				navigate("/create", { state: { userInfo: response.data.user } });
			} else {
				navigate("/home", { state: { userInfo: response.data.user } });
			}
		} catch (error) {
			setAuthError("red");
			toast.error(`${error}`);
		}
	};

	return (
		<styles.LoginBackgroundDiv>
			<Toaster position="top-center" richColors />
			<LoginForm
				isLoading={loginLoading}
				Loginhandler={handleLogin}
				errorUser={isUsernameError}
				errorPassword={isPasswordError}
				authError={authError}
			/>
		</styles.LoginBackgroundDiv>
	);
};

export default LoginPage;
