import styled from "styled-components";
import React from "react";
import colors from "../../../ui/colors";
import { Aduana } from "../../../enum/aduana-Options";
import { Carpetas } from "../../../dto/data-clients.request.dto";
import { Typography, Paper, Box } from "@mui/material";
import { transformDateString } from "../../../helpers/DateFormat";

export interface FolderResumeProps {
	FolderData: Carpetas;
	Role?: string;
}

export const FolderResume: React.FC<FolderResumeProps> = ({ FolderData }) => {
	const getCanalColor = (canal: string) => {
		switch (canal) {
			case "R":
				return { text: "ROJO", color: colors.red };
			case "V":
				return { text: "VERDE", color: colors.green };
			case "N":
				return { text: "NARANJA", color: colors.orange };
			default:
				return { text: "- - -", color: colors.grey };
		}
	};

	const folder = FolderData;
	const canalInfo = getCanalColor(folder.Carpetas.DatosDUA.Canal);

	return (
		<Paper
			elevation={3}
			sx={{
				height: "100%",
				border: `1px solid ${colors.primaryGreen}`,
				borderRadius: "8px",
				overflow: "hidden",
				boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)",
			}}
		>
			<Box
				sx={{
					backgroundColor: colors.primaryGreen,
					padding: "12px 16px",
					borderTopLeftRadius: "8px",
					borderTopRightRadius: "8px",
				}}
			>
				<Typography
					variant="h6"
					sx={{
						color: colors.white,
						fontWeight: 700,
						fontSize: "16px",
					}}
				>
					{folder.NombreCliente}
				</Typography>
				<Typography
					variant="subtitle1"
					sx={{
						color: colors.white,
						fontWeight: 600,
						fontSize: "14px",
					}}
				>
					Carpeta {folder.Carpetas.Identificacion.Carpeta}
				</Typography>
			</Box>

			<Box sx={{ padding: "16px" }}>
				<InfoRow>
					<InfoLabel>Aduana:</InfoLabel>
					<InfoValue>
						{`${folder.Carpetas.DatosDUA.AduanaOrigen} - ${
							Aduana[Number(folder.Carpetas.DatosDUA.AduanaOrigen)]
						}`}
					</InfoValue>
				</InfoRow>

				<InfoRow>
					<InfoLabel>Número de DUA:</InfoLabel>
					<InfoValue>{folder.Carpetas.DatosDUA.NroDUA}</InfoValue>
				</InfoRow>

				<InfoRow>
					<InfoLabel>Canal:</InfoLabel>
					<Box
						component="span"
						sx={{
							backgroundColor: `${canalInfo.color}20`,
							color: canalInfo.color,
							padding: "4px 8px",
							borderRadius: "4px",
							fontWeight: 600,
							fontSize: "13px",
						}}
					>
						{canalInfo.text}
					</Box>
				</InfoRow>

				<InfoRow>
					<InfoLabel>Fecha de numerado:</InfoLabel>
					<InfoValue>
						{transformDateString(folder.Carpetas.DatosDUA.FechaNumerado)}
					</InfoValue>
				</InfoRow>

				<InfoRow>
					<InfoLabel>Proveedor/Destinatario:</InfoLabel>
					<InfoValue>{folder.Carpetas.DatosDUA.Proveedor}</InfoValue>
				</InfoRow>
			</Box>
		</Paper>
	);
};

const InfoRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	flex-wrap: wrap;
`;

const InfoLabel = styled.span`
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 14px;
	color: ${colors.deepGreen};
	margin-right: 8px;
`;

const InfoValue = styled.span`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 14px;
	color: ${colors.primaryGreen};
`;
