import React from "react";
import { ButtonComponent } from "../../../template/Buttons/SimpleButton/Button";
import { UserOptions } from "../../../enum/user-Option";
import colors from "../../colors";
import SelectorMui from "../../../template/SelectorMui/SelectorMui";
import DateRangeSelector from "../../../template/DateRangeSelector/DateRangeSelector";
import { Grid, Box, Typography, Stack } from "@mui/material";

interface HeaderMainAreaProps {
	Role: string;
	activeButton: string;
	handleCompany: () => void;
	handleNews: () => void;
	selectorStatus: string;
	handleSortSelector: (value: string) => void;
	onDateFilter: (fromDate: string, toDate: string) => void;
	isFolder: boolean;
	singleTable: boolean;
}

const HeaderMainAreaComponent: React.FC<HeaderMainAreaProps> = ({
	activeButton,
	handleCompany,
	handleSortSelector,
	handleNews,
	onDateFilter,
	isFolder,
	Role,
	singleTable,
}) => {
	const roleClient = Role === "Cliente";

	return (
		<Box
			sx={{
				flexGrow: 1,
				margin: 2.5,
				padding: 1.5,
				borderRadius: 2,
				backgroundColor: colors.white,
			}}
		>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={12} sm={6} md={4} lg={3}>
					<Stack direction="row" spacing={2}>
						<ButtonComponent
							backgroundColor={
								activeButton === UserOptions.Company
									? colors.primaryGreen
									: colors.white
							}
							textColor={
								activeButton === UserOptions.Company
									? colors.white
									: colors.primaryGreen
							}
							buttonLabel={UserOptions.Company}
							OnClick={handleCompany}
						/>

						{!roleClient && (
							<ButtonComponent
								backgroundColor={
									activeButton === UserOptions.News
										? colors.primaryGreen
										: colors.white
								}
								textColor={
									activeButton === UserOptions.News
										? colors.white
										: colors.primaryGreen
								}
								buttonLabel={UserOptions.News}
								OnClick={handleNews}
							/>
						)}
					</Stack>
				</Grid>

				<Grid item xs={12} sm={6} md={8} lg={9}>
					<Stack
						direction={{ xs: "column", sm: "row" }}
						spacing={2}
						justifyContent="flex-end"
						alignItems="center"
					>
						{singleTable ? (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Typography
									variant="body2"
									sx={{ mr: 1, color: colors.deepGreen, fontWeight: 500 }}
								>
									Ordenar por:
								</Typography>
								<SelectorMui
									Options={[
										"Fecha Numerado +",
										"Fecha Numerado -",
										"Ref Cliente",
										"Carpetas",
									]}
									onChange={handleSortSelector}
								/>
							</Box>
						) : (
							!isFolder && (
								<>
									<DateRangeSelector onDateFilter={onDateFilter} />
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<SelectorMui
											Options={["Fecha +", "Fecha -", "Nombre", "Carpeta"]}
											onChange={handleSortSelector}
										/>
									</Box>
								</>
							)
						)}
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default HeaderMainAreaComponent;
