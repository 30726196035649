import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../colors";

interface ClientShowerBarProps {
	ClientName: string;
	Folders: string[];
	onClientNameClick: (clientName: string, folder: string) => void;
	onSelectionChange: () => void;
	isActive: boolean;
	index: number;
	Company_id: string;
}

export const ClientShowerBar: React.FC<ClientShowerBarProps> = ({
	ClientName,
	Company_id,
	Folders,
	onClientNameClick,
	onSelectionChange,
	isActive = false,
	index,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [activeFolder, setActiveFolder] = useState("");

	const handleClientClick = () => {
		if (!isExpanded && isActive) {
			onClientNameClick(Company_id, activeFolder);
		} else {
			onSelectionChange();
			onClientNameClick(Company_id, "");
		}
		setIsExpanded(!isExpanded);
	};

	const handleFolderClick = (folder: string) => {
		setActiveFolder(folder);
		onSelectionChange();
		onClientNameClick(Company_id, folder);
	};

	useEffect(() => {
		if (!isActive) {
			setActiveFolder("");
			setIsExpanded(false);
		}
	}, [isActive]);

	if (!ClientName) {
		return null;
	}

	return (
		<Container key={index}>
			<StyledClientName onClick={handleClientClick} $isActive={isActive}>
				{ClientName}
				<ExpandIcon $isExpanded={isExpanded}>▼</ExpandIcon>
			</StyledClientName>

			{isExpanded && (
				<FoldersContainer key={ClientName}>
					{Folders.sort().map((folder, idx) => (
						<FolderItem
							key={`${folder}-${idx}`}
							onClick={() => handleFolderClick(folder)}
							$isActive={folder === activeFolder}
						>
							{folder}
						</FolderItem>
					))}
				</FoldersContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
	transition: all 0.2s ease;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
`;

const StyledClientName = styled.h2<{ $isActive: boolean }>`
	font-family: "Montserrat";
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: ${(props) => (props.$isActive ? 700 : 600)};
	color: ${colors.white};
	padding: 0.75rem;
	margin: 0;
	cursor: pointer;
	transition: all 0.2s ease;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:hover {
		font-weight: 700;
		background-color: rgba(255, 255, 255, 0.05);
	}
`;

const ExpandIcon = styled.span<{ $isExpanded: boolean }>`
	font-size: 0.75rem;
	margin-right: 1rem;
	transform: ${(props) =>
		props.$isExpanded ? "rotate(0deg)" : "rotate(-90deg)"};
	transition: transform 0.2s ease;
	opacity: 0.7;
`;

const FoldersContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-top: 0.5rem;
	padding-bottom: 0.5rem;
	width: 90%;
`;

const FolderItem = styled.span<{ $isActive: boolean }>`
	font-family: "Montserrat";
	width: 90%;
	font-size: 1rem;
	font-weight: ${(props) => (props.$isActive ? 700 : 500)};
	color: ${colors.white};
	padding-left: 15%;
	cursor: pointer;
	transition: all 0.2s ease;

	&:hover {
		font-weight: 700;
	}

	${(props) =>
		props.$isActive &&
		`
    font-weight: 700;
    color: ${colors.white};
  `}
`;
