import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectCurrentUser, setAuthState } from "../slices/auth.slice";
import { Navigate } from "react-router-dom";

const ProtectedRoute: React.FC<any> = ({ children }) => {
	const user = useAppSelector((state) => selectCurrentUser(state));
	const dispatch = useAppDispatch();

	useEffect(() => {
		// Check if token exists in localStorage but user is not in state
		if (!user) {
			const token = localStorage.getItem("token");
			if (token) {
				dispatch(setAuthState({ token }));
			}
		}
	}, [user, dispatch]);

	// Get token from localStorage for the final check
	const token = localStorage.getItem("token");

	return user || token ? children : <Navigate to="/login" />;
};

export { ProtectedRoute };
