import React, { useState } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	OutlinedInput,
	MenuItem,
	FormControl,
	Select,
	Grid,
	Typography,
} from "@mui/material/";
import { SelectChangeEvent } from "@mui/material/Select";
import colors from "../../ui/colors";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

interface RangeProps {
	onDateFilter: (fromDate: string, toDate: string) => void;
}

export const DateRangeSelector: React.FC<RangeProps> = ({ onDateFilter }) => {
	const [open, setOpen] = useState(false);
	const [fromYear, setFromYear] = useState<string>("None");
	const [toYear, setToYear] = useState<string>("None");

	const years = ["2020", "2021", "2022", "2023", "2024"];

	const handleFromYear = (event: SelectChangeEvent<string>) => {
		setFromYear(event.target.value);
	};

	const handleToYear = (event: SelectChangeEvent<string>) => {
		setToYear(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (
		event: React.SyntheticEvent<unknown>,
		reason?: string
	) => {
		if (reason !== "backdropClick") {
			setFromYear("None");
			setToYear("None");
			onDateFilter("None", "None");
			setOpen(false);
		}
	};

	const handleFilter = () => {
		onDateFilter(fromYear, toYear);
		setOpen(false);
	};

	return (
		<Box>
			<Button
				onClick={handleClickOpen}
				variant="contained"
				startIcon={<FilterAltOutlinedIcon />}
				sx={{
					height: "2.5rem",
					backgroundColor: colors.primaryGreen,
					color: colors.white,
					"&:hover": {
						backgroundColor: colors.deepGreen,
					},
				}}
			>
				Filtrar
			</Button>

			<Dialog
				disableEscapeKeyDown
				open={open}
				onClose={handleClose}
				scroll="paper"
				PaperProps={{
					sx: {
						background: "#f0f0f0",
						minWidth: "320px",
						borderRadius: "8px",
						boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
					},
				}}
				BackdropProps={{
					style: {
						backgroundColor: colors.greenTransparent,
					},
				}}
			>
				<DialogTitle
					sx={{
						color: colors.primaryGreen,
						fontFamily: "Montserrat",
						fontSize: "1rem",
						fontWeight: "700",
						padding: "16px 24px 8px",
					}}
				>
					<Typography variant="h6" component="div">
						Filtrar por año
					</Typography>
				</DialogTitle>
				<DialogContent sx={{ padding: "8px 24px 16px" }}>
					<Box
						component="form"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Grid container spacing={2} sx={{ marginTop: 0.5 }}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel
										id="from-year-label"
										sx={{ color: colors.primaryGreen }}
									>
										Desde
									</InputLabel>
									<Select
										labelId="from-year-label"
										value={fromYear}
										onChange={handleFromYear}
										input={<OutlinedInput label="Desde" />}
										sx={{
											color: colors.primaryGreen,
											"& .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
											"&:hover .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
										}}
										MenuProps={{
											PaperProps: {
												sx: {
													maxHeight: 200,
												},
											},
										}}
									>
										<MenuItem value="None">
											<em>Ninguno</em>
										</MenuItem>
										{years.map((year) => (
											<MenuItem
												key={year}
												value={year}
												sx={{ color: colors.green }}
											>
												{year}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel
										id="to-year-label"
										sx={{ color: colors.primaryGreen }}
									>
										Hasta
									</InputLabel>
									<Select
										labelId="to-year-label"
										value={toYear}
										onChange={handleToYear}
										input={<OutlinedInput label="Hasta" />}
										sx={{
											color: colors.primaryGreen,
											"& .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
											"&:hover .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
											"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
												borderColor: colors.primaryGreen,
											},
										}}
										MenuProps={{
											PaperProps: {
												sx: {
													maxHeight: 200,
												},
											},
										}}
									>
										<MenuItem value="None">
											<em>Ninguno</em>
										</MenuItem>
										{years.map((year) => (
											<MenuItem
												key={year}
												value={year}
												sx={{ color: colors.green }}
											>
												{year}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions
					sx={{
						display: "flex",
						justifyContent: "space-between",
						padding: "8px 24px 16px",
					}}
				>
					<Button
						onClick={handleClose}
						variant="outlined"
						sx={{
							color: colors.primaryGreen,
							borderColor: colors.primaryGreen,
							"&:hover": {
								borderColor: colors.primaryGreen,
								backgroundColor: "rgba(0, 128, 0, 0.08)",
							},
						}}
					>
						Limpiar
					</Button>
					<Button
						onClick={handleFilter}
						variant="contained"
						sx={{
							backgroundColor: colors.primaryGreen,
							color: colors.white,
							"&:hover": {
								backgroundColor: colors.deepGreen,
							},
						}}
					>
						Aplicar filtro
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DateRangeSelector;
