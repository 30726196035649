import React, { useCallback, useEffect, useState } from "react";
import { tableCellClasses } from "@mui/material/TableCell";
import {
	Paper,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	Table,
	TableBody,
	TablePagination,
	Skeleton,
	Box,
	Typography,
	Tooltip,
} from "@mui/material";

import styled from "styled-components";
import colors from "../../../ui/colors";
import { getClientData } from "../../../apis/data.api";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { transformDateString } from "../../../helpers/DateFormat";

type Carpeta = {
	Carpeta: string;
	FechaNumerado: string;
	DUA: string;
	RefCliente: string;
	Canal: string;
	Facturas: string;
};

type CompanyBrief = {
	Carpetas: Carpeta[];
	NombreCliente: string;
	Cliente: string;
	_id: string;
};

const filterToFieldMap: {
	[key: string]: { field: string; order: "asc" | "desc" };
} = {
	"Fecha Numerado +": { field: "FechaNumerado", order: "desc" },
	"Fecha Numerado -": { field: "FechaNumerado", order: "asc" },
	RefCliente: { field: "RefCliente", order: "desc" },
	Carpetas: { field: "Carpeta", order: "desc" },
};

interface ClientsTableProps {
	CompanySelectedId: string;
	FolderSelected: (clientsName: string, folder: string) => void;
	searchTable: string | undefined;
	DateRange: string;
	Filter: string;
}

export const SingleClientTable: React.FC<ClientsTableProps> = ({
	CompanySelectedId,
	FolderSelected,
	searchTable,
	Filter,
}) => {
	const token = useSelector((state: RootState) => state.auth.token);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [ClientData, setClientData] = useState<CompanyBrief | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const getClient = useCallback(async () => {
		try {
			setIsLoading(true);

			let searchTerm: string | undefined = undefined;
			if (searchTable && searchTable.trim().length > 0) {
				searchTerm = searchTable.trim();
			}

			const sortOption = filterToFieldMap[Filter] || {
				field: "FechaNumerado",
				order: "desc",
			};

			const Data = await getClientData(
				CompanySelectedId,
				token as string,
				searchTerm,
				sortOption.field,
				sortOption.order
			);

			const clientData = Data[0];
			if (searchTerm) {
				clientData.Carpetas = clientData.Carpetas.filter(
					(carpeta: Carpeta) =>
						carpeta.RefCliente === searchTerm ||
						carpeta.Carpeta === searchTerm ||
						carpeta.DUA === searchTerm
				);
			}

			setClientData(clientData);
		} catch (error) {
			console.error("Error fetching single client data:", error);
		} finally {
			setIsLoading(false);
		}
	}, [token, CompanySelectedId, searchTable, Filter]);

	useEffect(() => {
		getClient();
	}, [getClient]);

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleFolderClick = (Company_id: string, folderQuest: string) => {
		FolderSelected(Company_id, folderQuest);
	};

	if (!ClientData || isLoading) {
		return (
			<TableWrap>
				<Paper elevation={0} sx={{ maxWidth: "97%", borderRadius: "12px" }}>
					<TableContainer
						component={Paper}
						elevation={0}
						style={{ maxHeight: "600px", border: "none", borderRadius: "12px" }}
					>
						<Table aria-label="ClientsTable" sx={{ background: colors.white }}>
							<TableHead>
								<TableRow>
									<StyledTableCell>Nombre</StyledTableCell>
									<StyledTableCell>Carpeta</StyledTableCell>
									<StyledTableCell>Referencia Cliente</StyledTableCell>
									<StyledTableCell>Canales</StyledTableCell>
									<StyledTableCell>Facturas</StyledTableCell>
									<StyledTableCell>Fechas numerado</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array(12)
									.fill(0)
									.map((_, index) => (
										<TableRow key={index}>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
											<StyledTableCell>
												<Skeleton variant="text" animation="wave" />
											</StyledTableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</TableWrap>
		);
	}

	const { Carpetas, _id } = ClientData;
	const NombreCliente = ClientData.NombreCliente[0];

	return (
		<TableWrap>
			<Paper
				elevation={2}
				style={{
					backgroundColor: colors.white,
					borderRadius: "12px",
					overflow: "hidden",
					border: `2px solid ${colors.primaryGreen}`,
				}}
			>
				<StyledTableContainer
					style={{
						maxHeight: 600,

						borderBottom: `1px solid ${colors.primaryGreen}`,
					}}
				>
					<Table
						sx={{
							minWidth: "200px",
							background: colors.white,
							height: 100,
							borderRadius: "4px",
						}}
						aria-label="ClientsTable"
						stickyHeader
					>
						<TableHead>
							<TableRow
								style={{
									background: colors.white,
								}}
							>
								<StyledTableCell>Nombre</StyledTableCell>
								<StyledTableCell>Carpeta</StyledTableCell>
								<StyledTableCell>DUA</StyledTableCell>
								<StyledTableCell>Referencia Cliente</StyledTableCell>
								<StyledTableCell>Canales</StyledTableCell>
								<StyledTableCell>Facturas</StyledTableCell>
								<StyledTableCell>Fechas numerado</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{Carpetas.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage
							).map((data, index) => {
								const getCanalColor = (canal: string) => {
									switch (canal) {
										case "V":
											return `${colors.green}`;
										case "R":
											return `${colors.red}`;
										case "A":
											return `${colors.yellow}`;
										case "N":
											return `${colors.orange}`;
										default:
											return `${colors.primaryGreen}`;
									}
								};

								const getCanalName = (canal: string) => {
									switch (canal) {
										case "V":
											return "Verde";
										case "R":
											return "Rojo";
										case "A":
											return "Amarillo";
										case "N":
											return "Naranja";
										case "":
											return "- - -";
										default:
											return canal;
									}
								};

								const canalColor = getCanalColor(data.Canal);
								const canalName = getCanalName(data.Canal);

								return (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th": { border: 0 },
											"&:hover": {
												backgroundColor: `#f0f0f0`,
												transition: "background-color 0.3s",
											},
										}}
									>
										<StyledTableCell align="left" scope="row">
											<Typography variant="body2" fontWeight={500}>
												{NombreCliente}
											</Typography>
										</StyledTableCell>

										<StyledTableCell align="center">
											<FolderButton
												onClick={() => handleFolderClick(_id, data.Carpeta)}
											>
												{data.Carpeta}
											</FolderButton>
										</StyledTableCell>

										<StyledTableCell align="center">
											{data.DUA || "- - -"}
										</StyledTableCell>

										<StyledTableCell align="center">
											{data.RefCliente || "- - -"}
										</StyledTableCell>

										<StyledTableCell align="center">
											<Box
												sx={{
													display: "inline-block",
													backgroundColor: `${canalColor}20`,
													color: canalColor,
													padding: "4px 8px",
													borderRadius: "4px",
													fontWeight: 600,
													minWidth: "80px",
												}}
											>
												{canalName}
											</Box>
										</StyledTableCell>

										<StyledTableCell align="center">
											<Tooltip
												title={
													Array.isArray(data.Facturas) &&
													data.Facturas.length > 0
														? data.Facturas.join(", ")
														: "Sin facturas"
												}
												arrow
												placement="top"
											>
												<Typography
													variant="body2"
													noWrap
													sx={{ maxWidth: 120 }}
												>
													{Array.isArray(data.Facturas) &&
													data.Facturas.length > 0
														? data.Facturas.join(", ")
														: "- - -"}
												</Typography>
											</Tooltip>
										</StyledTableCell>

										<StyledTableCell align="center">
											{transformDateString(data.FechaNumerado)}
										</StyledTableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</StyledTableContainer>
				<TablePagination
					component="div"
					count={Carpetas.length}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					sx={{
						color: colors.primaryGreen,
						backgroundColor: colors.white,
						"& .MuiSelect-select.MuiSelect-select": {
							backgroundColor: colors.grey,
						},
						"& .MuiTablePagination-selectIcon": {
							color: colors.primaryGreen,
						},
						"& .MuiButtonBase-root": {
							color: colors.primaryGreen,
						},
					}}
				/>
			</Paper>
		</TableWrap>
	);
};

export const TableWrap = styled.div`
	margin-top: 2%;
	width: 96%;
	height: 100%;
	padding-left: 2.5%;
	border-radius: 12px;
`;

const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: colors.white,
		color: colors.primaryGreen,
		fontFamily: "Montserrat",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "0.8rem",
		lineHeight: "1.2rem",
		letterSpacing: "0.2px",
		textAlign: "center",
		padding: "16px 8px",
	},
	[`&.${tableCellClasses.body}`]: {
		alignItems: "center",
		textAlign: "center",
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.75rem",
		lineHeight: "1.2rem",
		letterSpacing: "0.2px",
		borderBottom: `2px solid ${colors.primaryGreen}20`,
		padding: "12px 8px",
	},
}));

const FolderButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	color: ${colors.primaryGreen};
	text-decoration: underline;
	font-weight: 600;
	transition: color 0.3s;
	padding: 4px 8px;
	border-radius: 4px;

	&:hover {
		color: ${colors.green};
		background-color: "#f0f0f0";
	}
`;

const StyledTableContainer = styled(TableContainer)`
	::-webkit-scrollbar {
		width: 12px;
	}

	::-webkit-scrollbar-track {
		background: ${colors.white};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${colors.primaryGreen};
		border-radius: 20px;
		border: 3px solid ${colors.white};
	}
`;
